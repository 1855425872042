<template>
    <div class="container">
        <qy-top-nav title="认证经销商"/>
        <van-form @submit="onSubmit">
            <van-field
                    v-model="form.dealerName"
                    label="经销商"
                    placeholder="请填写经销商名称"
                    readonly
            />
            <van-field
                    v-model="form.name"
                    name="负责人"
                    label="负责人"
                    placeholder="请填写负责人姓名"
                    readonly
            />
            <van-field
                    v-model="form.phone"
                    type="tel"
                    label="联系电话"
                    placeholder="请填写负责人电话"
                    readonly
            />
            <van-field
                    v-model="form.licenseNo"
                    type="text"
                    label="营业执照编号"
                    placeholder="请填写营业执照编号"
                    readonly
            />
            <van-field name="uploader" label="营业执照">
                <template #input>
                    <van-uploader v-model="licenseImgs" upload-text="添加图片" deletable="false" :max-count="mcl" />
                </template>
            </van-field>

            <van-field name="uploader" label="店铺图片">
                <template #input>
                    <van-uploader v-model="images" upload-text="添加图片" deletable="false" :max-count="mcs" />
                </template>
            </van-field>

            <van-field v-model="form.address" rows="2" autosize label="店铺地址" type="textarea" maxlength="50" placeholder="请输入店铺详细地址..." show-word-limit readonly
                       :rules="[{required:true }]"/>
        </van-form>
    </div>
</template>
<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    export default {
        name: "DetailDealer",
        data(){
            return {
                images:[],
                licenseImgs:[],
                mcs:3,
                mcl:2,
                form:{
                    id:'',
                    dealerName:'',
                    name:'',
                    phone:'',
                    shopImg:'',
                    licenseImg:'',
                    licenseNo:'',
                    address:'',
                }
            }
        },
        created(){
            this.getList();
        },
        methods:{
            getList(){
                let id = this.getUrlParam("id");
                if(!id) return;
                let that = this;
                request.post('/api/dealer/queryDealerById/'+id).then(res => {
                    if (res.code == 0){
                        that.form = res.data;
                        if(that.form.shopImg){
                            let list = that.form.shopImg.split(',');
                            that.mcs = list.length;
                            list.forEach(item => {
                                that.images.push({
                                 url: this.getImgUrl(item),
                                 file:{
                                     url:item,
                                 }
                               });
                            });
                        }
                        if(that.form.licenseImg){
                            let list = that.form.licenseImg.split(',');
                            that.mcl = list.length;
                            list.forEach(item => {
                                that.licenseImgs.push({
                                    url: this.getImgUrl(item),
                                    file:{
                                        url:item,
                                    }
                                });
                            });
                        }
                    }else {
                        //.........
                    }
                });
            }
        }
    }
</script>

<style scoped>
</style>